<template>
<div>

    <template>
        <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
                <b-breadcrumb v-if="$route.params.site_id && $route.params.location_id" :items="breadCrumb()"/>
                <b-breadcrumb v-else :items="breadCrumb2()"/>
            </div>
        </div>
    </template>

    <b-row class="match-height">
        <b-col lg="12" md="12">
            <b-card title="Change Background">

                <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                    <div class="alert-body">
                        {{error_message}}
                    </div>
                </b-alert>
                
                <b-form @submit="formSubmit">

                    <b-row>
                        <b-col md="12" >                        
                            <b-form-group label="Project Site" class="required">
                                <b-form-select v-model="form.site" @change="resetData()">
                                    <b-form-select-option value="" disabled>Select Project Site</b-form-select-option>
                                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name}}</b-form-select-option>
                                </b-form-select>                                
                            </b-form-group>
                        </b-col>

                        <b-col md="12" >
                            <b-form-group label="Location" class="required">
                                <b-form-select v-model="form.location" @change="getData()">
                                    <b-form-select-option value="" disabled>Select Location</b-form-select-option>
                                    <b-form-select-option :value="location._id" v-for="location in locations" :key="location._id">{{location.name}}</b-form-select-option>
                                </b-form-select>                           
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <div v-if="form.location">

                        <b-row class="mt-3" ref="imgs">
                            <b-col md="4" cols="6" sm="6" v-for="(img, ind) in images" :key="ind" >
                                <b-card bg-variant="transparent" 
                                    text-variant="black" 
                                    class="overflow-hidden" 
                                    :border-variant="form.image != img._id ? 'secondary' : 'danger'"
                                    style="border-width: 10px !important"
                                    @click="updateSelect(ind)"
                                >
                                    <center>
                                        <img class="img-fluid" :src="img.image" variant="light-primary" style="width:100%"/>
                                    </center> 
                                </b-card>                        
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col class="mt-1">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1">
                                    Update 
                                </b-button>
                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'feedback-panel-settings', params:{ site_id: $route.params.site_id, location_id: $route.params.location_id } })">
                                    Cancel
                                </b-button>
                            </b-col>
                        </b-row>

                    </div>

                    
                </b-form>

            </b-card>
        </b-col>
    </b-row>

</div>
</template>

<script>

import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BAlert,
    BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia,
    BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, BBreadcrumb
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { GET_API, POST_API } from "../../../store/actions.type";
import vSelect from 'vue-select';

export default {
    components: {
        BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, 
        BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, 
        BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, vSelect, BBreadcrumb
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            error_message        : null,
            showDismissibleAlert : false,

            sites     : [],
            locations : [],
            images    : [],

            form: {
                site     : this.$route.params.site_id ? this.$route.params.site_id : '',
                location : this.$route.params.location_id ? this.$route.params.location_id : '',
                image    : '',
            }
        }
    },

    methods : {
        updateSelect(ind){
            this.form.image = this.images[ind]._id;
        },

        getBackgrounds(){
            return this.$store.dispatch(POST_API, {
                data:{
                    site         : this.form.site,
                    location     : this.form.location
                },
                api: '/api/get-background-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.images = this.$store.getters.getResults.data;                
                }
            });
        },

        getData(){
            return this.$store.dispatch(POST_API, {
                data:{
                    setting_name : 'change_background',
                    // site         : this.form.site,
                    location     : this.form.location
                },
                api: '/api/get-feedback-panel-setting-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    var data = this.$store.getters.getResults.data;

                    this.form.image = data.image;
                }
            });
        },

        formSubmit(e){
            e.preventDefault();

            return this.$store.dispatch(POST_API, {
                data:{
                    site     : this.form.site,
                    location : this.form.location,
                    image    : this.form.image,
                },
                api: '/api/change-background'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;

                    this.successAlert().then((result) => {
                        this.$router.push({ name:'feedback-panel-settings', params:{ site_id: this.$route.params.site_id, location_id: this.$route.params.location_id } })
                    });
                    
                }
            });        
        },

        allSites(){
            return this.$store.dispatch(POST_API, {
                data:{
                    role:this.$store.getters.currentUser.role,
                    om_sites:this.$store.getters.currentUser.om_sites,
                },
                api: '/api/all-sites'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data = this.$store.getters.getResults.data;

                    this.sites = data;
                    return this.sites;
                }
            });
        },

        allLocations(){
            return this.$store.dispatch(POST_API, {
                data:{
                    site:this.form.site
                },
                api: '/api/all-locations'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    this.locations = data;
                    return this.locations;
                }
            });
        },

        resetData(){
            this.form.location = '',
            this.form.image    = null,
            
            this.allLocations();
        },

        breadCrumb(){
            var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
            },{
                to: null,
                text: 'Project Sites'
            },{
                to: {name:'job-sites'},
                text: 'Sites',
            },{
                to: {name:'site-locations',params:{site_id: this.$route.params.site_id}},
                text: 'Locations',
            },{
                to: {name:'feedback-panel-settings',params:{ site_id: this.$route.params.site_id, location_id: this.$route.params.location_id }},
                text: 'Panel Settings',
            },{
                to: null,
                text: 'Change Background',
                active: true
            }];
            return item;
        },

        breadCrumb2(){
            var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
            },{
                to:null,
                text: 'QR Feedback',
            },{
                to: {name:'feedback-panel-settings'},
                text:'Panel Settings',
            },{
                to:null,
                text:'Change Background',
                active:true
            }];
            return item;
        }

    },

    mounted(){
        this.allSites().then(()=>{
            this.allLocations().then(()=>{
                this.getBackgrounds().then(()=> {
                    this.getData();
                })
            })
        }); 
    }
}
</script>

